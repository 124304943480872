import Velocity from 'velocity-animate';

const LOGIN = 'login';
const REGISTER = 'register';

class Wayfarer {
    constructor(options) {
        this.options = {};
        this.options.mode = LOGIN;
        this.options.routes = {};
        this.options.actionSlider = null;
        this.options.actionContainer = null;
        this.options.actions = {};
        this.options.actions.loginBtn = null;
        this.options.actions.registerBtn = null;
        this.options.sliderOffset = 411;
        this.options.sliderPadding = 32;
        this.options.animation = {};
        this.options.animation.easing = [400,20];
        this.options.animation.duration = 600;
        this.options = Object.assign(this.options, options);

        this.historyData = {};
        this.init();
        window.addEventListener('popstate',this.handleHistory);
    }

    init = () => {
        if(this.options.actions.loginBtn !== null && this.options.actions.registerBtn !== null) {
            this.options.actions.loginBtn.onclick = this.showLogin;
            this.options.actions.registerBtn.onclick = this.showRegistration;
        }

        this.pwinit();

        switch (this.options.mode) {
            case LOGIN:
                if(this.options.actionSlider !== null && this.options.actionContainer !== null) {
                    this.options.actionSlider.classList.remove('registration-active');
                    this.options.actionSlider.classList.add('login-active');
                    this.options.actionSlider.style.left = this.options.sliderOffset + 'px';
                    this.options.actionContainer.style.left = "-" + (this.options.sliderOffset + this.options.sliderPadding) + 'px';
                }
                history.replaceState(REGISTER, window.title, window.location.pathname+window.location.search);
                console.log('pushed '+REGISTER);
                break;
            case REGISTER:
                if(this.options.actionSlider !== null && this.options.actionContainer !== null) {
                    this.options.actionSlider.classList.remove('login-active');
                    this.options.actionSlider.classList.add('registration-active');
                    this.options.actionSlider.style.left = '0px';
                    this.options.actionContainer.style.left = this.options.sliderPadding + 'px';
                }

                setTimeout(function(id){
                    $('#'+id).focus();
                    if($('#'+id).prop('type') !== 'email') {
                        let len = $('#'+id).val().length;
                        $('#'+id)[0].setSelectionRange(len, len);
                    }
                }, 10, ($('#user_registration_email')[0].value.length > 0 ? 'user_registration_firstName' : 'user_registration_email'));

                history.replaceState(LOGIN, window.title, window.location.pathname+window.location.search);
                console.log('pushed '+LOGIN);
                break;
        }

    };

    showLogin = (setHistory = true) => {
        // console.log('mode is '+this.options.mode);
        if (setHistory) {
            this.pushState(this.options.mode, 'login');
        }
        this.options.mode = LOGIN;
        this.options.actionSlider.classList.remove('registration-active');
        this.options.actionSlider.classList.add('login-active');
        Velocity(this.options.actionSlider, {
            left: this.options.sliderOffset + 'px'
        }, this.options.animation);
        Velocity(this.options.actionContainer, {
            left: "-" + (this.options.sliderOffset + this.options.sliderPadding) + 'px'
        }, this.options.animation);
    };

    showRegistration = (setHistory = true) => {
        // console.log('mode is '+this.options.mode);
        if (setHistory) {
            this.pushState(this.options.mode, 'register');
        }
        this.options.mode = REGISTER;
        this.options.actionSlider.classList.remove('login-active');
        this.options.actionSlider.classList.add('registration-active');
        Velocity(this.options.actionSlider, {
            left: '0px'
        }, this.options.animation);
        Velocity(this.options.actionContainer, {
            left: this.options.sliderPadding + 'px'
        }, this.options.animation);
    };

    pwinit = () => {
        $('#login-password-toggle').on('click', function () {
            if($('#login-form-password-input').attr('type') !== 'password') {
                $('#login-form-password-input').attr('type','password');
                $('#login-password-toggle').addClass('fa-eye-slash');
                $('#login-password-toggle').removeClass('fa-eye');
            } else {
                $('#login-form-password-input').attr('type','text');
                $('#login-password-toggle').addClass('fa-eye');
                $('#login-password-toggle').removeClass('fa-eye-slash');
            }
        });
    
        $('#register-password-toggle').on('click', function (ev) {
            if(($('#user_registration_password_first').attr('type') !== 'password') ||
            ($('#user_registration_password_second').attr('type') !== 'password')) {
                $('#user_registration_password_first').attr('type','password'); 
                $('#user_registration_password_second').attr('type','password');
                $('#register-password-toggle').addClass('fa-eye-slash');
                $('#register-password-toggle').removeClass('fa-eye');
            } else {
                $('#user_registration_password_first').attr('type','text'); 
                $('#user_registration_password_second').attr('type','text');
                $('#register-password-toggle').addClass('fa-eye');
                $('#register-password-toggle').removeClass('fa-eye-slash');
            }
        });
    };

    pushState = (mode, path, title='Clubster') => {
        history.pushState(mode, title, path+window.location.search);
        console.log('pushed '+mode);
        if(mode === 'login') {
            if($('#login-form-password-input').attr('type') !== 'password') {
                $('#login-form-password-input').attr('type','password');
                $('#login-password-toggle').addClass('fa-eye-slash');
                $('#login-password-toggle').removeClass('fa-eye');
            }
        } else if(mode === 'register') {
            if(($('#user_registration_password_first').attr('type') !== 'password') ||
               ($('#user_registration_password_second').attr('type') !== 'password')) {
                $('#user_registration_password_first').attr('type','password'); 
                $('#user_registration_password_second').attr('type','password');
                $('#register-password-toggle').addClass('fa-eye-slash');
                $('#register-password-toggle').removeClass('fa-eye');
            }
        }
    };

    handleHistory = (e) => {
        let mode = e.state;
        // console.log('state is '+e);
        console.log('popped '+mode);
        switch (mode) {
            case LOGIN:
                console.log('showing reg');
                this.showRegistration(false);
                break;
            case REGISTER:
                console.log('showing login');
                this.showLogin(false);
                break;
        }
        return true;
    };
}

global.Wayfarer = Wayfarer;
export default Wayfarer;
