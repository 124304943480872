class MediaViewer {
  constructor(activatorSelector) {
    this.activatorSelector = activatorSelector;
    this.imageContainer = null;
    this.throbber = null;
    this.info = null;
    this.mediaFileIndex = 0;
    this.mediaFiles = [];
    this.currentWidth = 0;
    this.currentHeight = 0;
  }

  setupEvents = () => {
    $(this.activatorSelector).on('click', this.displayViewer);
  };

  updateEvents = () => {
    $(this.activatorSelector).off('click', this.displayViewer);
    $(this.activatorSelector).on('click', this.displayViewer);
  };

  displayViewer = (event) => {
    let target = $(event.target);
    let postId = target.data('postId');
    let mediaIndex = target.data('mediaIndex');
    let mediaItems = $('.media-item[data-post-id="'+postId+'"]');

    this.mediaFiles = [];

    let mediaViewer = $('<div>');
    mediaViewer.addClass('media-viewer');
    mediaViewer.css({'z-index':'999999'});

    let backdrop = $('<div>');
    backdrop.addClass('media-viewer-backdrop');
    backdrop.on('click', this.hideViewer);
    mediaViewer.append(backdrop);

    this.throbber = $('<div>');
    this.throbber.id = 'media-viewer-throbber';
    this.throbber.html('<i class="fas fa-spinner fa-pulse"></i> Loading');
    this.throbber.css({'z-index':'999999'});
    this.throbber.addClass('hidden text-white text-5xl');
    mediaViewer.append(this.throbber);

    this.content = $('<div>');
    this.content.addClass('media-viewer-content');
    this.content.css({display:'none'});
    mediaViewer.append(this.content);

    if (mediaItems.length > 1) {
      let prev = $('<div>');
      prev.addClass('media-viewer-prev');
      this.content.append(prev);
      prev.on('click', function () {
        let prevIndex = this.getPrevImageIndex();
        this.setView(prevIndex);
      }.bind(this));

      let next = $('<div>');
      next.addClass('media-viewer-next');
      this.content.append(next);
      next.on('click', function () {
        let nextIndex = this.getNextImageIndex();
        this.setView(nextIndex);
      }.bind(this));
    }

    let close = $('<div>');
    close.addClass('media-viewer-close');
    this.content.append(close);
    close.on('click', this.hideViewer);

    this.info = $('<div>');
    this.info.addClass('media-viewer-info');
    this.content.append(this.info);

    mediaItems.each(function (i, mediaItem) {
      if ($(mediaItem).data('mediaType') === undefined) {
         return;
     }
      this.mediaFiles.push({
        'url':$(mediaItem).data('mediaUrl'),
        'type':$(mediaItem).data('mediaType'),
        'image':$(mediaItem).data('mediaImage'),
        'height':$(mediaItem).data('mediaHeight'),
        'width':$(mediaItem).data('mediaWidth'),
      });
    }.bind(this));

    let mediaItemContainer = $('<div>');
    mediaItemContainer.addClass('media-viewer-item');
    this.content.append(mediaItemContainer);

    this.image = $('<img id="media-viewer-image">');
    mediaItemContainer.append(this.image);

    this.imageContainer = $('<div id="media-viewer-image-container">');
    mediaItemContainer.append(this.imageContainer);

    $(document.body).append(mediaViewer).addClass('media-viewer-scroll-disabled');
    mediaViewer.fadeIn();
    this.setView(mediaIndex);
  };

  hideViewer = () => {
    $('.media-viewer').fadeOut(function (){
      $('.media-viewer').remove();
      $(document.body).removeClass('media-viewer-scroll-disabled');
    });
  };

  setView = (mediaFileIndex) => {
    this.mediaFileIndex = mediaFileIndex;
    let filename = this.mediaFiles[mediaFileIndex].url;
    let filetype = this.mediaFiles[mediaFileIndex].type;

    if (filetype == 'image') {
      this.setImage(filename);
    }else if (filetype == 'video') {
      this.setVideo(filename);
    }else if (filetype == 'youtubevideo') {
      this.setYoutubeEmbed(filename,
                           this.mediaFiles[mediaFileIndex].width, 
                           this.mediaFiles[mediaFileIndex].height);
    }
  };

  setImage = (filename) => {
    let preloader = new Image();
    preloader.onload = function () {
      let oldMedia = $('#media-viewer-image');
      oldMedia.attr('id','old-media-viewer-image');

      let oldMediaWidth = oldMedia.width();
      let oldMediaHeight = oldMedia.height();

      oldMedia.animate({
        opacity: 0.00,
        width: (oldMediaWidth/2),
        height: (oldMediaHeight/2)
      }, 350, 'swing', function (e) {
        this.content.show();
        oldMedia.css({
          position:'absolute',
          top:0,
          left:0,
          right: 0,
          bottom:0
        });

        let {viewWidth, viewHeight} = this.determineViewDimensions(preloader.width, preloader.height);

        let image = $('<img id="media-viewer-image">');
        image.attr({
          'src': filename
        });

        image.css({
          'opacity':0,
          width: oldMediaWidth/2,
          height: oldMediaHeight/2
        });

        this.imageContainer.append(image);

        image.animate({
          opacity: 1,
          width: viewWidth,
          height: viewHeight
        }, 350, 'swing');

        this.currentWidth = viewWidth;
        this.currentHeight = viewHeight;
        oldMedia.remove();

        this.info.html((this.mediaFileIndex + 1) + ' of ' + (this.mediaFiles.length) + ' files');
      }.bind(this));


    }.bind(this);
    preloader.src = filename;
  };

  setVideo = (filename) => {
    // preloader.on('canPlay', function() {

      let oldMedia = $('#media-viewer-image');
      oldMedia.attr('id', 'old-media-viewer-image');

      let preloader = $('<video />', {
        id: 'media-viewer-image',
        src: filename,
        type: 'video/mp4',
        controls: true
      });

      //  preloader.on('loadedmetadata', function (metadataEvent) {
      //    console.log('metadataloaded');
      //    console.log(metadataEvent);
      // });
      preloader.on('loadeddata', function (dataEvent) {
      let oldMediaWidth = oldMedia.width();
      let oldMediaHeight = oldMedia.height();

      oldMedia.animate({
        opacity: 0.00,
        width: (oldMediaWidth / 2),
        height: (oldMediaHeight / 2)
      }, 350, 'swing', function (e) {
        this.content.show();
        oldMedia.css({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        });

        let {viewWidth, viewHeight} = this.determineViewDimensions(dataEvent.target.videoWidth, dataEvent.target.videoHeight);

        preloader.css({
          'opacity': 0,
          width: oldMediaWidth / 2,
          height: oldMediaHeight / 2
        });

        preloader.animate({
          opacity: 1,
          width: viewWidth,
          height: viewHeight
        }, 350, 'swing');

        this.currentWidth = viewWidth;
        this.currentHeight = viewHeight;
        oldMedia.remove();

        this.info.html((this.mediaFileIndex + 1) + ' of ' + (this.mediaFiles.length) + ' files');
      }.bind(this));
    }.bind(this));

    preloader.css({
      'opacity': 0,
      width: 1,
      height: 1
    });
    this.imageContainer.append(preloader);
    // preloader.src = filename;
  };

    setYoutubeEmbed = (filename, width, height) => {
        let oldMedia = $('#media-viewer-image');
        oldMedia.attr('id', 'old-media-viewer-image');
        this.throbber.show();
    
        if (width < (window.innerWidth / 2)) {
            let ratio = height / width;
            width = parseInt(window.innerWidth / 2);
            height = parseInt(height / ratio);
        }
    
        let preloader = $('<iframe />', {
            id: 'media-viewer-image',
            src: filename + '&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&modestbranding=1',
            frameBorder: 0,
            scrolling: 'no',
            marginHeight: 0,
            marginWidth: 0,
            width: width,
            height: height,
            type: 'text/html'
        });
    
        preloader.on('load', function(dataEvent) {
            let oldMediaWidth = oldMedia.width();
            let oldMediaHeight = oldMedia.height();
    
            oldMedia.animate({
                opacity: 0.00,
                width: (oldMediaWidth / 2),
                height: (oldMediaHeight / 2)
            }, 350, 'swing', function(e) {
                this.content.show();
                oldMedia.css({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                });
    
                let viewWidth = width;
                let viewHeight = height;
    
                preloader.css({
                    'opacity': 0,
                    width: oldMediaWidth / 2,
                    height: oldMediaHeight / 2
                });
    
                preloader.animate({
                    opacity: 1,
                    width: viewWidth,
                    height: viewHeight
                }, 350, 'swing');
    
                this.currentWidth = viewWidth;
                this.currentHeight = viewHeight;
                oldMedia.remove();
    
                this.info.html((this.mediaFileIndex + 1) + ' of ' + (this.mediaFiles.length) + ' files');
    
                this.throbber.hide();
            }.bind(this));
        }.bind(this));
    
        preloader.css({
            'opacity': 0,
            width: 1,
            height: 1
        });
        this.imageContainer.append(preloader);
    };

  determineViewDimensions(fullWidth, fullHeight) {
    let windowWidth = $(window).width() * 0.75;
    let windowHeight = $(window).height() * 0.75;
    let maxWidth = windowWidth - 32;
    let maxHeight = windowHeight - 32;

    let viewWidth = maxWidth;
    let viewHeight = maxHeight;

    if (fullWidth > maxWidth || fullHeight > maxHeight) {
      if (fullWidth / maxWidth > fullHeight / maxHeight) {
        viewHeight = parseInt(fullHeight / (fullWidth / viewWidth), 10);
      } else {
        viewWidth = parseInt(fullWidth / (fullHeight / viewHeight), 10);
      }
    } else {
      viewWidth = fullWidth;
      viewHeight = fullHeight;
    }
    return {viewWidth, viewHeight};
  }

  getPrevImageIndex = () => {
    let prevIndex = this.mediaFileIndex - 1;
    if (prevIndex < 0) {
      return this.mediaFiles.length - 1;
    }
    return prevIndex;
  };

  getNextImageIndex = () => {
    let nextIndex = this.mediaFileIndex + 1;
    if (nextIndex === this.mediaFiles.length) {
      return 0;
    }
    return nextIndex;
  };
}

global.MediaViewer = MediaViewer;
export default MediaViewer;
