class Messenger {
    userId;
    identity;
    server;
    ws;

    constructor(server, userId, autoconnect) {
        this.server = server;
        this.userId = userId;
        if (autoconnect) {
            this.connect();
        }
    }

    connect = () => {
       this.ws = new WebSocket(this.server);
       this.ws.addEventListener('message', this.receive);
       this.ws.addEventListener('ident', this.ident.bind(this));
    }

    ident = (event) => {
        if (!event.detail.id) {
            throw "WS IDENT FAILED";
        }
        this.identity = event.detail.id;
        this.send('Messaging:Ident', {
            userId: this.userId
        })
    }

    joinChannel = (channel, announceJoin) => {
        this.send('Messaging:Channel:JoinChannel', {
            channelId: channel,
            announceJoin: announceJoin
        })
    }

    createMessageIntention = (channel) => {
        this.send('Messaging:CreateMessageIntention', {
            channelId: channel
        });
    }

    cancelMessageIntention = (channel) => {
        this.send('Messaging:TimeoutMessageIntention', {
            channelId: channel,
            intentionId: this.userId
        });
    }

    sendMessage = (channel, message) => {
        this.send('Messaging:CreateMessage', {
            channelId: channel,
            message: message
        });
    }

    send = (task, payload) => {
        payload.ident = this.identity;
        this.ws.send(JSON.stringify({
            task: task,
            payload: payload
        }));
    }

    receive = (packet) => {
        let payload = JSON.parse(packet.data);
        this.trigger(payload.type, payload.data);
    }

    trigger = (eventName, data) => {
        let event = new CustomEvent(eventName, {
            detail: data
        });
        this.ws.dispatchEvent(event);
    }

    addEventListener = (event, callback) => {
        this.ws.addEventListener(event, callback);
    }
}

global.Messenger = Messenger;
export default Messenger;
