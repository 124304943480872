require ('../components/wayfarer/component');
require ('../components/media-viewer/media-viewer');
require ('../images/favicon.ico');
import axios from 'axios';
import rrule from 'rrule';
import Messenger from '../components/messenger/messenger';

$(function (){
    $('.comment-input-container').each(setupCommentEditor);
    $('.comments').each(setupCommentControls);

    function setupCommentEditor () {
// cancel comment button
        $('.comment-input-cancel', this).on('click', function (event) {
            let postId = $(this).data('postId');
            let commentId = $(this).data('commentId');

            let container;
            let editorId;
            if(typeof commentId === 'undefined') {
                container = $('.comment-input-container[data-post-id="'+postId+'"]');
                editorId = '#comment-input-'+postId;
            } else {
                container = $('.comment-input-container[data-comment-id="'+commentId+'"]');
                editorId = '#reply-input-'+commentId;
                $('#reply-'+commentId).hide();
                $('.comment-message-container[data-comment-id="'+commentId+'"]')[0]._x_dataStack[0].create_reply = false;
            }

            EditorUtility.removeEditorById(editorId);

            $(editorId).val('');
            container.removeClass('edited');
        });

// post comment button
        $('.comment-input-post', this).on('click', function (event) {
            let userId = $(this).data('userId');
            let postId = $(this).data('postId');
            let commentId = $(this).data('commentId');

            let container;
            let input;
            let postBtn;
            let cancelBtn;

            if(typeof commentId === 'undefined') {
                container = $('.comment-input-container[data-post-id="'+postId+'"]');
                input = $('#comment-input-'+postId);
                postBtn = $('.comment-input-post[data-post-id="'+postId+'"]');
                cancelBtn = $('.comment-input-cancel[data-post-id="'+postId+'"]');
            } else {
                container = $('.comment-input-container[data-comment-id="'+commentId+'"]');
                input = $('#reply-input-'+commentId);
                postBtn = $('.comment-input-post[data-comment-id="'+commentId+'"]');
                cancelBtn = $('.comment-input-cancel[data-comment-id="'+commentId+'"]');
            }

            let content = EditorUtility.getActiveEditorContent(true);

            if (content.trim().length > 0) {
                postBtn.prop('disabled','disabled');
                postBtn.attr('disabled','disabled');
                cancelBtn.prop('disabled','disabled');
                cancelBtn.attr('disabled','disabled');
                const params = new URLSearchParams();
                params.append('userId', userId);
                params.append('postId', postId);
                if(typeof commentId !== 'undefined') {
                    params.append('parentId', commentId);
                }
                params.append('message', content);

                axios.post('/comment', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                .then(function (response){
                    let resp = $(response.data);
                    resp.each(setupCommentControls);
                    let commentBody = $(resp).find('.tinymce-comment')[0];
                    commentBody.innerHTML = EditorUtility.entityToHtml(commentBody.innerHTML);

                    if(typeof commentId === 'undefined') {
                        $('.comments-container[data-post-id="'+postId+'"]').append(resp);
                        let currentCount = parseInt($('.post-comment-count[data-post-id="'+postId+'"]').text());
                        $('.post-comment-count[data-post-id="'+postId+'"]').text(++currentCount);
                    } else {
                        $('.comment-replies-container[data-comment-id="'+commentId+'"]').append(resp);
                        $('#reply-'+commentId).hide();
                        $('.comment-message-container[data-comment-id="'+commentId+'"]')[0]._x_dataStack[0].create_reply = false;
                    }
                    EditorUtility.removeActiveEditor(true);

                    container.removeClass('edited');
                    postBtn.removeProp('disabled');
                    postBtn.removeAttr('disabled');
                    cancelBtn.removeProp('disabled');
                    cancelBtn.removeAttr('disabled');

                    timeago();
                })
                .catch(function (error) {
                    postBtn.removeProp('disabled');
                    postBtn.removeAttr('disabled');
                    cancelBtn.removeProp('disabled');
                    cancelBtn.removeAttr('disabled');
                });
            } else {
                container.removeClass('edited');
                EditorUtility.removeActiveEditor();
            }
        });
    }

    function setupCommentControls() {
        $('.comment-delete', this).off('click');
        $('.comment-delete', this).on('click', function (event) {
            let postId = $(this).data('postId');
            let commentId = $(this).data('commentId');
            let comment = $('.comment[data-comment-id="'+commentId+'"]');
            let replies = $('.comment-replies-container[data-comment-id="'+commentId+'"]');

            const params = new URLSearchParams();
            params.append('postId', postId);
            params.append('commentId', commentId);

            if(replies.length > 0) {
                if(replies[0].children.length > 0) {
                    $('#comment-data-div[data-post-id="'+postId+'"]')[0]._x_dataStack[0].deleteModalMsg = 'Are you sure you want to delete this comment and all of its replies?';
                } else {
                    $('#comment-data-div[data-post-id="'+postId+'"]')[0]._x_dataStack[0].deleteModalMsg = 'Are you sure you want to delete this comment?';
                }
            } else {
                $('#comment-data-div[data-post-id="'+postId+'"]')[0]._x_dataStack[0].deleteModalMsg = 'Are you sure you want to delete this reply?';
            }

            $('#comment-data-div[data-post-id="'+postId+'"]')[0]._x_dataStack[0].showDeleteModal = true;

            $('.actn-comment-delete[data-post-id="'+postId+'"]').one('click', function (event) {
                $('.actn-comment-no-delete[data-post-id="'+postId+'"]').off('click');
                $('#comment-data-div[data-post-id="'+postId+'"]')[0]._x_dataStack[0].showDeleteModal = false;
                $('.comment-message-container[data-comment-id="'+commentId+'"]')[0]._x_dataStack[0].create_reply = false;
                $('#reply-'+commentId).hide();
                replies.slideUp(150, function () {
                    replies.remove();
                });
            
                comment.slideUp(150, function () {
                    comment.remove();
                });
            
                axios.post('/comment/delete', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function(r) {
                    timeago();
                });
            });
            $('.actn-comment-no-delete[data-post-id="'+postId+'"]').one('click', function (event) {
                $('.actn-comment-delete[data-post-id="'+postId+'"]').off('click');
                $('#comment-data-div[data-post-id="'+postId+'"]')[0]._x_dataStack[0].showDeleteModal = false;
            });
        });
    }

    var mediaViewer = new MediaViewer('.media-item');
    mediaViewer.setupEvents();

// this appears to be "do-nothing" code
// part of src/Twig/AInclude/AIncludeExtension.php ???
    let elms = $('[data-ainclude]');
    elms.each(function (index) {
        let $elm = $(this);
        $elm.html(
            '<div class="ajax-spinner dark">\n' +
            ' <div class="rect1"></div>\n' +
            ' <div class="rect2"></div>\n' +
            ' <div class="rect3"></div>\n' +
            ' <div class="rect4"></div>\n' +
            ' <div class="rect5"></div>\n' +
            '</div>'
        );
        let url = $elm.data('ainclude');
        $.get(url).done(function(html){
            $elm.html(html).css('display','block');
        });
    });
// "do-nothing" code ends here

    // feed automatic loader
    function loadFeedPage (trigger) {
        let loadMsg = $('<div>');
        let loadAnim = $('<div>');

        loadMsg.html('Loading More...');
        loadMsg.addClass('feedLoading');

        loadAnim.addClass('feedLoading');
        loadAnim.html(
            '<div class="ajax-spinner dark">\n' +
            ' <div class="rect1"></div>\n' +
            ' <div class="rect2"></div>\n' +
            ' <div class="rect3"></div>\n' +
            ' <div class="rect4"></div>\n' +
            ' <div class="rect5"></div>\n' +
            '</div>'
        );

        loadMsg.append(loadAnim);

        trigger.before(loadMsg);
        trigger.remove();
        let offset = trigger.data('postCount');
        let organization = trigger.data('postOrganization');
        let contact = trigger.data('postContact');
        let event = trigger.data('postEvent');
        let user = trigger.data('postUser');
        let listUrl = trigger.data('postListUrl');
        let showHidden = trigger.data('showHidden');
        let honorSticky = trigger.data('honorSticky');

        $.post(listUrl, {
            offset: offset,
            organization: organization,
            contact: contact,
            event: event,
            user: user,
            showHidden: showHidden,
            honorSticky: honorSticky,
        }).done(function (posts) {
            loadMsg.hide();
            let feedPage = $('body').data('feedPage')||1;
            if (posts.length > 0) {
                let separator = $('<div>');
                let pageNumber = feedPage+1;
                separator.addClass('page-separator').html('Page '+pageNumber);
                // added page number to class, for selection only not styling
                separator.addClass('page-number-'+pageNumber);
                $('body').data('feedPage', pageNumber);
                loadMsg.before(separator);

                $.each(posts, function (i, post) {
                    offset++;
                    post = $(post);
                    let tmp = post.children();
                    // NOTE: if debugging with <details> then the [0] index is wrong
                    if((tmp.length > 0) && (tmp[0].querySelector('.actn-post-like') !== null)) {
                        tmp[0].querySelectorAll('.actn-post-like').forEach((el) => {
                            el.addEventListener('click',likeListener);
                        });
                        if(tmp[0].querySelector('.actn-post-like-show') !== null) {
                            tmp[0].querySelector('.actn-post-like-show').addEventListener('click',viewLikesListener);
                        }
                    }
                    loadMsg.before(post);
                    $.each(post,setupCommentEditor);
                    $.each(post,setupCommentControls);
                });
                EditorUtility.initTinyMCEComments();
                mediaViewer.updateEvents();
            }else{
                // no more
                let endOfFeed = $('<div>');
                endOfFeed.html('There are no more items to load');
                endOfFeed.addClass('feedEnd');
                $('#feed').append(endOfFeed);
            }
            timeago();
            loadMsg.remove();
        }).fail(function (){
            loadMsg.html('Nothing Left To Load').delay(5000).fadeOut(function () {
                $(this).remove();
            });

        });
    }

    function loadNotificationPage (trigger) {
        let loadMsg = $('<div>');
        let loadAnim = $('<div>');

        loadMsg.html('Loading More...');
        loadMsg.addClass('feedLoading');

        loadAnim.addClass('feedLoading');
        loadAnim.html(
            '<div class="ajax-spinner dark">\n' +
            ' <div class="rect1"></div>\n' +
            ' <div class="rect2"></div>\n' +
            ' <div class="rect3"></div>\n' +
            ' <div class="rect4"></div>\n' +
            ' <div class="rect5"></div>\n' +
            '</div>'
        );

        loadMsg.append(loadAnim);

        trigger.before(loadMsg);
        trigger.remove();
        let offset = trigger.data('notificationCount');
        let listUrl = trigger.data('notificationListUrl');

        $.post(listUrl, {
            offset: offset,
        }).done(function (notifications) {
            loadMsg.remove();
            if (notifications.length > 0) {
                $.each(notifications, function (i, notification) {
                    offset++;
                    $('#notification-list').append(notification);
                });
                timeago();
                $('#notification-display-count').text($('#notification-list').children('.notification-item').length);
            }else{
                // no more
                let endOfFeed = $('<div>');
                endOfFeed.html('There are no more notifications to load');
                endOfFeed.addClass('feedEnd');
                $('#notification-list').append(endOfFeed);
            }
        }).fail(function (){
            loadMsg.html('Nothing Left To Load').delay(5000).fadeOut(function () {
                $(this).remove();
            });
        });
    }

    $(window).on('updatemediaviewerevents', function () {
        mediaViewer.updateEvents();
    });

    $(window).on('resize scroll', function () {
        let waypoint = $('#pageWaypoint');
        if (waypoint.length > 0) {
            var top_of_element = waypoint.offset().top;
            var bottom_of_element = waypoint.offset().top + waypoint.outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();

            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                loadFeedPage(waypoint);
            }
        }

        let notificationWaypoint = $('#notificationWaypoint');
        if (notificationWaypoint.length > 0) {
            var top_of_element = notificationWaypoint.offset().top;
            var bottom_of_element = notificationWaypoint.offset().top + notificationWaypoint.outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();

            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                loadNotificationPage(notificationWaypoint);
            }
        }
    });

// club "welcome" message, this will dismiss it
    $('[data-dismiss]').on('click', function () {
        let dismissable = $(this).data('dismiss');
        $(dismissable).slideUp().fadeOut();
    });

// wayfarer mobile, "register" button
    $('.activity-button').on('click', function(e){
        let form = $(this).parents('form');
        if ($(form)[0].checkValidity()) {
            let currentText = $(this).html();
            $(this).html('<i class="fas fa-spinner fa-spin"></i> ' + currentText);
            $(this).addClass('disabled');
            $(this).attr('disabled', 'disabled');
            $(this).prop('disabled', 'disabled');
            $(form)[0].submit();
        }
    });

    $('.wait-for-submit').each(function (indx, button) {
        $(button).prepend($('<i style="text-align: center; width: 20px; line-height: 20px;" class="wait-for-submit-spinner fas fa-spinner fa-spin"></i>'));
    });

    $('.wait-for-submit').on('click', function (e) {
        if($(e.target.form)[0].checkValidity()) {
            $(this).addClass('disabled');
        }
    });

    $('form').on('keypress', function(evt) {
        if(evt.keyCode === 13 && evt.target.type !== 'textarea') {
            switch(evt.target.form.id) {
                case 'registration-form':
                    if($('#registration-form-terms').find('input[type="checkbox"]').is(':checked')) {
                        $('#'+evt.target.form.id).find(':submit').click();
                    }
                    break;

                case 'login-form':
                    $('#'+evt.target.form.id).find(':submit').click();
                    break;

                default:
                    evt.preventDefault();
                    break;
            };
        }
    });
});

global.axios = axios;
global.rrule = rrule;
